var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        mouseover: _vm.topCenterMouseover,
        mouseout: _vm.topCenterMouseout,
      },
    },
    [
      _vm._l(_vm.contentList, function (item) {
        return [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.visible,
                  expression: "item.visible",
                },
              ],
              key: item.id,
              staticClass: "drag-content-list",
              style:
                "z-index:" +
                item.zIndex +
                ";top:" +
                (item.mouseY - 20) +
                "px" +
                (item.mouseX
                  ? `;left:${item.mouseX - 20}px;`
                  : ";right:130px;") +
                `min-height:${item.id == 4 ? "85px" : "100px"}`,
            },
            [
              _c(
                "div",
                {
                  staticClass: "drag-content-title",
                  on: {
                    mousedown: (e) => {
                      _vm.mousedown(e, item)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(item.title))]),
                  _c(
                    "div",
                    {
                      staticClass: "pushpin-icon",
                      on: {
                        mousemove: function ($event) {
                          _vm.noDrag = true
                        },
                        mouseout: function ($event) {
                          _vm.noDrag = false
                        },
                      },
                    },
                    [
                      item.id == 3
                        ? _c("a-icon", {
                            staticStyle: { "font-size": "16px" },
                            attrs: { type: "redo" },
                            on: { click: _vm.getPageList },
                          })
                        : _vm._e(),
                      _c("pushpin", {
                        staticStyle: { "margin-left": "6px" },
                        attrs: {
                          theme: "outline",
                          size: "16",
                          fill: item.isPushpin ? _vm.color : item.pushpinColor,
                          strokeWidth: 3,
                        },
                        on: {
                          mouseover: function ($event) {
                            item.pushpinColor = _vm.color
                          },
                          mouseout: function ($event) {
                            item.pushpinColor = "#4a4a4a"
                          },
                          click: function ($event) {
                            item.isPushpin = !item.isPushpin
                          },
                        },
                      }),
                      !item.allHeight && item.id != 1 && item.id != 101
                        ? _c("a-icon", {
                            staticStyle: { "margin-left": "6px" },
                            attrs: { type: "column-height" },
                            on: {
                              click: function ($event) {
                                return _vm.changeAllheight(item)
                              },
                            },
                          })
                        : item.allHeight && item.id != 1 && item.id != 101
                        ? _c("a-icon", {
                            staticStyle: { "margin-left": "6px" },
                            attrs: { type: "vertical-align-middle" },
                            on: {
                              click: function ($event) {
                                return _vm.changeAllheight(item)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("a-icon", {
                        staticStyle: { "margin-left": "6px" },
                        attrs: { type: "close" },
                        on: {
                          click: function ($event) {
                            return _vm.closeContentList(item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "drag-content-show",
                  style: item.allHeight
                    ? { height: _vm.bottomHeight - 100 + "px" }
                    : "",
                  on: {
                    click: function ($event) {
                      return _vm.topItem(item)
                    },
                  },
                },
                [
                  item.id == 4
                    ? _c("div", [
                        _vm.showAnnotationList.length &&
                        _vm.checkPermission("local:view")
                          ? _c(
                              "div",
                              { staticStyle: { padding: "12px 18px" } },
                              [
                                _c("a-checkbox-group", {
                                  attrs: {
                                    options: [
                                      { label: "翻译环节", value: "翻译环节" },
                                      { label: "制作环节", value: "制作环节" },
                                    ],
                                  },
                                  model: {
                                    value: _vm.checkedToStages,
                                    callback: function ($$v) {
                                      _vm.checkedToStages = $$v
                                    },
                                    expression: "checkedToStages",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "local-show",
                            style: item.allHeight
                              ? {
                                  maxHeight:
                                    _vm.bottomHeight - 100 + "px !important",
                                }
                              : "max-height: 400px;",
                          },
                          [
                            _vm.showAnnotationList.length &&
                            _vm.checkPermission("local:view")
                              ? _c(
                                  "div",
                                  { staticClass: "open-all-annotation-list" },
                                  [
                                    _vm.leftAnnotationList.some((page) =>
                                      page.annotationList.some(
                                        (anItem) => !anItem.selected
                                      )
                                    ) ||
                                    _vm.rightAnnotationList.some((page) =>
                                      page.annotationList.some(
                                        (anItem) => !anItem.selected
                                      )
                                    )
                                      ? _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openAnnotationList.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("全展开/收起")]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.closeAnnotationList.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("全展开/收起")]
                                        ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.showAnnotationList.length &&
                            _vm.checkPermission("local:view")
                              ? _c(
                                  "a-collapse",
                                  {
                                    attrs: { bordered: false },
                                    on: {
                                      mousemove: function ($event) {
                                        _vm.noDrag = true
                                      },
                                      mouseout: function ($event) {
                                        _vm.noDrag = false
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "expandIcon",
                                          fn: function (props) {
                                            return [
                                              _c("a-icon", {
                                                attrs: {
                                                  type: "caret-right",
                                                  rotate: props.isActive
                                                    ? 90
                                                    : 0,
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.showAnnotationOpenKey,
                                      callback: function ($$v) {
                                        _vm.showAnnotationOpenKey = $$v
                                      },
                                      expression: "showAnnotationOpenKey",
                                    },
                                  },
                                  _vm._l(
                                    _vm.showAnnotationList.filter(
                                      (page) =>
                                        page.annotationList &&
                                        page.annotationList.filter((annoItem) =>
                                          _vm.checkedToStages.some(
                                            (toStage) =>
                                              toStage == annoItem.toStage
                                          )
                                        ).length
                                    ),
                                    function (page, pageIdx) {
                                      return _c(
                                        "a-collapse-panel",
                                        {
                                          key: pageIdx + "",
                                          style: _vm.customStyle,
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "header" },
                                              slot: "header",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    `第${
                                                      _vm.pageListData.findIndex(
                                                        (p) =>
                                                          p.id == page.pageId
                                                      ) + 1
                                                    }页`
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "20px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      `共${
                                                        page.annotationList.filter(
                                                          (annoItem) =>
                                                            _vm.checkedToStages.some(
                                                              (toStage) =>
                                                                toStage ==
                                                                annoItem.toStage
                                                            )
                                                        ).length
                                                      }处`
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._l(
                                            page.annotationList.filter(
                                              (annoItem) =>
                                                _vm.checkedToStages.some(
                                                  (toStage) =>
                                                    toStage == annoItem.toStage
                                                )
                                            ),
                                            function (annoItem) {
                                              return _c(
                                                "div",
                                                {
                                                  key: annoItem.id,
                                                  class:
                                                    _vm.selectId === annoItem.id
                                                      ? "local-content-list-show local-content-list-show-selected"
                                                      : "local-content-list-show",
                                                  attrs: {
                                                    id:
                                                      "local-list-item-" +
                                                      annoItem.id,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toLocal(
                                                        annoItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "local-content-list-num-two",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            annoItem.sequenceNum
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  annoItem.commentData &&
                                                  annoItem.commentData.length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "local-content-list",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                padding:
                                                                  "5px 0 5px 0",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    annoItem.toStage
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            annoItem.commentData,
                                                            function (i, idx) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "anno" +
                                                                    idx,
                                                                  staticClass:
                                                                    "local-content-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-item-top",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-item-top-left",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src: i.avatar,
                                                                                  alt: "",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-item-top-right",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-item-top-right-user",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      i.userName
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  class:
                                                                                    _vm.selectId ==
                                                                                    annoItem.id
                                                                                      ? "action-span-select"
                                                                                      : "action-span",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "a-dropdown",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          trigger:
                                                                                            [
                                                                                              "click",
                                                                                            ],
                                                                                          transitionName:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      (_vm.checkPermission(
                                                                                        "local:dele"
                                                                                      ) ||
                                                                                        _vm.checkPermission(
                                                                                          "local:edit"
                                                                                        ) ||
                                                                                        i.userId ==
                                                                                          _vm
                                                                                            .userInfo
                                                                                            .id ||
                                                                                        (annoItem.toStage ==
                                                                                          "制作环节" &&
                                                                                          (_vm
                                                                                            .taskDetail
                                                                                            .stageName ==
                                                                                            "终审" ||
                                                                                            _vm
                                                                                              .taskDetail
                                                                                              .stageName ==
                                                                                              "翻译" ||
                                                                                            _vm
                                                                                              .taskDetail
                                                                                              .stageName ==
                                                                                              "校对"))) &&
                                                                                      _vm.changeAll
                                                                                        ? _c(
                                                                                            "a-icon",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "more",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "a-menu",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              slot: "overlay",
                                                                                            },
                                                                                          slot: "overlay",
                                                                                        },
                                                                                        [
                                                                                          (_vm.checkPermission(
                                                                                            "local:edit"
                                                                                          ) ||
                                                                                            i.userId ==
                                                                                              _vm
                                                                                                .userInfo
                                                                                                .id) &&
                                                                                          _vm.changeAll
                                                                                            ? _c(
                                                                                                "a-menu-item",
                                                                                                {
                                                                                                  key: "0",
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.editLocalContent(
                                                                                                          i,
                                                                                                          annoItem
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                    编辑\n                                  "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          (_vm.checkPermission(
                                                                                            "local:dele"
                                                                                          ) ||
                                                                                            i.userId ==
                                                                                              _vm
                                                                                                .userInfo
                                                                                                .id ||
                                                                                            (annoItem.toStage ==
                                                                                              "制作环节" &&
                                                                                              (_vm
                                                                                                .taskDetail
                                                                                                .stageName ==
                                                                                                "终审" ||
                                                                                                _vm
                                                                                                  .taskDetail
                                                                                                  .stageName ==
                                                                                                  "翻译" ||
                                                                                                _vm
                                                                                                  .taskDetail
                                                                                                  .stageName ==
                                                                                                  "校对"))) &&
                                                                                          _vm.changeAll
                                                                                            ? _c(
                                                                                                "a-menu-item",
                                                                                                {
                                                                                                  key: "1",
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.deletLocal(
                                                                                                          annoItem,
                                                                                                          i
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                    删除\n                                  "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    i.createTime
                                                                                  ) +
                                                                                  "\n                          "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-item-bottom",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.toLocal(
                                                                              annoItem
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      !i.editble
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              domProps:
                                                                                {
                                                                                  innerHTML:
                                                                                    _vm._s(
                                                                                      i.content
                                                                                    ),
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "padding-right":
                                                                                    "5px",
                                                                                  "padding-top":
                                                                                    "3px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "j-editor",
                                                                                {
                                                                                  ref:
                                                                                    "localInput-local-" +
                                                                                    annoItem.id,
                                                                                  refInFor: true,
                                                                                  attrs:
                                                                                    {
                                                                                      placeholder:
                                                                                        "ALT+回车换行，回车发送",
                                                                                      maxWidth:
                                                                                        "50px",
                                                                                      toolbar:
                                                                                        [],
                                                                                    },
                                                                                  on: {
                                                                                    postValue:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.localEnter(
                                                                                          null,
                                                                                          annoItem,
                                                                                          i
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        i.content,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            i,
                                                                                            "content",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "i.content",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _vm.selectId ===
                                                            annoItem.id &&
                                                          annoItem.commentData &&
                                                          annoItem.commentData.every(
                                                            (i) => !i.editble
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-top":
                                                                      "5px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "j-editor",
                                                                    {
                                                                      ref: "localInput-local-send",
                                                                      refInFor: true,
                                                                      attrs: {
                                                                        placeholder:
                                                                          "ALT+回车换行，回车发送",
                                                                        maxWidth:
                                                                          "50px",
                                                                        toolbar:
                                                                          [],
                                                                      },
                                                                      on: {
                                                                        postValue:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.pushLoacal(
                                                                              annoItem
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.postValue,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.postValue =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "postValue",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-top": "15px",
                                      "padding-bottom": "15px",
                                    },
                                  },
                                  [_c("a-empty")],
                                  1
                                ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  item.id == 3 &&
                  (_vm.showOptions.leftOptions.length == 1 ||
                    (_vm.showOptions.leftOptions.length > 1 &&
                      !_vm.showOptions.leftOptions[1].checked))
                    ? _c(
                        "div",
                        {
                          staticClass: "text-list-show",
                          style: item.allHeight
                            ? { height: _vm.bottomHeight - 100 + "px" }
                            : "",
                        },
                        [
                          _c("div", { staticClass: "text-list-top" }, [
                            _c("span", { staticClass: "text-list-radio" }, [
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.textView == "originalText"
                                      ? "text-list-radio-selected"
                                      : "text-list-radio1",
                                  on: {
                                    click: function ($event) {
                                      _vm.textView = "originalText"
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      style:
                                        _vm.textView == "originalText"
                                          ? "border:none"
                                          : "",
                                    },
                                    [_vm._v("原文")]
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.textView == "translateText"
                                      ? "text-list-radio-selected"
                                      : "text-list-radio2",
                                  on: {
                                    click: function ($event) {
                                      _vm.textView = "translateText"
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      style:
                                        _vm.textView == "revisionText"
                                          ? "border:none"
                                          : "",
                                    },
                                    [_vm._v("译文")]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _vm.textView == "originalText"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                共" +
                                        _vm._s(
                                          _vm.getTextAreaNum("originalText")
                                        ) +
                                        "个文本，" +
                                        _vm._s(_vm.getTextNum("originalText")) +
                                        "个字\n              "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " 共" +
                                        _vm._s(_vm.getTextAreaNum("showText")) +
                                        "个文本，" +
                                        _vm._s(_vm.getTextNum("showText")) +
                                        "个字 "
                                    ),
                                  ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "text-list-center",
                              on: {
                                mousemove: function ($event) {
                                  _vm.noDrag = true
                                },
                                mouseout: function ($event) {
                                  _vm.noDrag = false
                                },
                              },
                            },
                            [
                              _c("a-input-search", {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请输入内容" },
                                on: { search: _vm.textSearch },
                                model: {
                                  value: _vm.textInputValue,
                                  callback: function ($$v) {
                                    _vm.textInputValue = $$v
                                  },
                                  expression: "textInputValue",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text-list-bottom",
                              style: item.allHeight
                                ? {
                                    maxHeight:
                                      _vm.bottomHeight - 210 + "px !important",
                                  }
                                : "max-height: 400px;",
                              attrs: { id: "text-list-modal" },
                            },
                            [
                              _vm.pageListData.length
                                ? _c(
                                    "div",
                                    { staticClass: "open-all-annotation-list" },
                                    [
                                      _vm.textDefaultKey.length !=
                                      _vm.textList.length
                                        ? _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openTextList.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("全展开/收起")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.closeTextList.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("全展开/收起")]
                                          ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.pageListData.length
                                ? _c(
                                    "a-collapse",
                                    {
                                      attrs: { bordered: false },
                                      on: {
                                        mousemove: function ($event) {
                                          _vm.noDrag = true
                                        },
                                        mouseout: function ($event) {
                                          _vm.noDrag = false
                                        },
                                        change: _vm.textDefaultKeyChange,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "expandIcon",
                                            fn: function (props) {
                                              return [
                                                _c("a-icon", {
                                                  attrs: {
                                                    type: "caret-right",
                                                    rotate: props.isActive
                                                      ? 90
                                                      : 0,
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.textDefaultKey,
                                        callback: function ($$v) {
                                          _vm.textDefaultKey = $$v
                                        },
                                        expression: "textDefaultKey",
                                      },
                                    },
                                    _vm._l(
                                      _vm.textList,
                                      function (page, pageIdx) {
                                        return _c(
                                          "a-collapse-panel",
                                          {
                                            key: page.pageId + "",
                                            style: _vm.customStyle,
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "header" },
                                                slot: "header",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      `第${page.pageSequenceNum}页`
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        `共${
                                                          page.textAreaCount
                                                        }个文本，${
                                                          _vm.textView ==
                                                          "originalText"
                                                            ? page.originWordCount
                                                            : page.translationWordCount
                                                        }个字`
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              page.textList,
                                              function (item) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    class:
                                                      _vm.selectTextId ===
                                                      item.id
                                                        ? "local-content-list-show local-content-list-show-selected"
                                                        : "local-content-list-show",
                                                    attrs: {
                                                      id: `textList-${item.id}`,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toText(
                                                          page,
                                                          item,
                                                          page.pageSequenceNum -
                                                            1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "local-content-list-num",
                                                        staticStyle: {
                                                          "padding-top": "7px",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.textAreaSequenceNum
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "local-content-list",
                                                      },
                                                      [
                                                        _vm.textView !=
                                                        "translateText"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "space-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.originText
                                                                  )
                                                                ),
                                                                item.originText
                                                                  ? _c(
                                                                      "a-icon",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "copy",
                                                                              rawName:
                                                                                "v-copy",
                                                                              value:
                                                                                item.originText,
                                                                              expression:
                                                                                "item.originText",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          type: "copy",
                                                                        },
                                                                        on: {
                                                                          mouseup:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.copyMessage.apply(
                                                                                null,
                                                                                arguments
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : [
                                                              _c("div", [
                                                                item.translationText &&
                                                                !item.imgUrl
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "space-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .phoneticList
                                                                              .length
                                                                              ? item.translationText.slice(
                                                                                  0,
                                                                                  3
                                                                                ) +
                                                                                  item.phoneticList
                                                                                    .map(
                                                                                      (
                                                                                        p
                                                                                      ) =>
                                                                                        p.text
                                                                                    )
                                                                                    .join(
                                                                                      ""
                                                                                    )
                                                                              : item.translationText
                                                                          )
                                                                        ),
                                                                        _c(
                                                                          "a-icon",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "copy",
                                                                                  rawName:
                                                                                    "v-copy",
                                                                                  value:
                                                                                    item
                                                                                      .phoneticList
                                                                                      .length
                                                                                      ? item.phoneticList
                                                                                          .map(
                                                                                            (
                                                                                              p
                                                                                            ) =>
                                                                                              p.text
                                                                                          )
                                                                                          .join(
                                                                                            ""
                                                                                          )
                                                                                      : item.translationText.slice(
                                                                                          3,
                                                                                          item
                                                                                            .translationText
                                                                                            .length
                                                                                        ),
                                                                                  expression:
                                                                                    "\n                              item.phoneticList.length\n                                ? item.phoneticList.map(p => p.text).join('')\n                                : item.translationText.slice(3, item.translationText.length)\n                            ",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                type: "copy",
                                                                              },
                                                                            on: {
                                                                              mouseup:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.copyMessage.apply(
                                                                                    null,
                                                                                    arguments
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : item.imgUrl
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "rgba(0,0,0,0.25)",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              "图片附件"
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  item.id == 5 &&
                  (_vm.showOptions.leftOptions.length == 1 ||
                    (_vm.showOptions.leftOptions.length > 1 &&
                      !_vm.showOptions.leftOptions[1].checked))
                    ? _c("div", { staticClass: "text-list-show" }, [
                        _c("div", { staticClass: "text-list-top" }),
                        _c(
                          "div",
                          {
                            staticClass: "text-list-center",
                            staticStyle: { "padding-top": "0" },
                            on: {
                              mousemove: function ($event) {
                                _vm.noDrag = true
                              },
                              mouseout: function ($event) {
                                _vm.noDrag = false
                              },
                            },
                          },
                          [
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 5 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "margin-right": "2px",
                                      },
                                    },
                                    [_vm._v("阶段")]
                                  ),
                                ]),
                                _c("a-col", { attrs: { span: 19 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "revision-action",
                                      staticStyle: {
                                        height: "40px",
                                        display: "flex",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-list-top" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-list-radio",
                                              staticStyle: {
                                                "line-height": "18px",
                                              },
                                              on: {
                                                click: _vm.changeRevisionList,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    _vm.revisionType == 1
                                                      ? "text-list-radio-selected"
                                                      : "text-list-radio1",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.revisionType = 1
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        _vm.revisionType == 1
                                                          ? "border:none"
                                                          : "",
                                                    },
                                                    [_vm._v("校对")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  class:
                                                    _vm.revisionType == 2
                                                      ? "text-list-radio-selected"
                                                      : "text-list-radio2",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.revisionType = 2
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        _vm.revisionType == 2
                                                          ? "border:none"
                                                          : "",
                                                    },
                                                    [_vm._v("终审")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 5 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "margin-right": "2px",
                                      },
                                    },
                                    [_vm._v("文本框状态")]
                                  ),
                                ]),
                                _c("a-col", { attrs: { span: 19 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "revision-action" },
                                    [
                                      _c("a-checkbox-group", {
                                        attrs: { options: _vm.errorOptions },
                                        on: { change: _vm.changeRevisionList },
                                        model: {
                                          value: _vm.errors,
                                          callback: function ($$v) {
                                            _vm.errors = $$v
                                          },
                                          expression: "errors",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 5 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "margin-right": "2px",
                                      },
                                    },
                                    [_vm._v("修改状态")]
                                  ),
                                ]),
                                _c("a-col", { attrs: { span: 19 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "revision-action" },
                                    [
                                      _c(
                                        "a-radio-group",
                                        {
                                          on: {
                                            change: _vm.changeRevisionList,
                                          },
                                          model: {
                                            value: _vm.changeSignValue,
                                            callback: function ($$v) {
                                              _vm.changeSignValue = $$v
                                            },
                                            expression: "changeSignValue",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 0 } },
                                            [
                                              _vm._v(
                                                "\n                      全部\n                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 2 } },
                                            [
                                              _vm._v(
                                                "\n                      已解决\n                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 1 } },
                                            [
                                              _vm._v(
                                                "\n                      未解决\n                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 5 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "margin-right": "2px",
                                      },
                                    },
                                    [_vm._v("修订记录")]
                                  ),
                                ]),
                                _c("a-col", { attrs: { span: 19 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "revision-action" },
                                    [
                                      _c(
                                        "a-radio-group",
                                        {
                                          on: {
                                            change: _vm.changeRevisionList,
                                          },
                                          model: {
                                            value: _vm.openEditHistory,
                                            callback: function ($$v) {
                                              _vm.openEditHistory = $$v
                                            },
                                            expression: "openEditHistory",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 1 } },
                                            [
                                              _vm._v(
                                                "\n                      显示\n                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 0 } },
                                            [
                                              _vm._v(
                                                "\n                      不显示\n                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-list-bottom",
                            style: item.allHeight
                              ? {
                                  maxHeight:
                                    _vm.bottomHeight - 370 + "px !important",
                                }
                              : "max-height: 400px;",
                            attrs: { id: "revisiton-modal" },
                          },
                          [
                            _vm.revisionList && _vm.revisionList.length
                              ? _c(
                                  "div",
                                  { staticClass: "open-all-annotation-list" },
                                  [
                                    _vm.reDefaultKey.length !=
                                    _vm.revisionList.length
                                      ? _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openReList.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("全展开/收起")]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.closeReList.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("全展开/收起")]
                                        ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.revisionList && _vm.revisionList.length
                              ? _c(
                                  "a-collapse",
                                  {
                                    attrs: { bordered: false },
                                    on: {
                                      mousemove: function ($event) {
                                        _vm.noDrag = true
                                      },
                                      mouseout: function ($event) {
                                        _vm.noDrag = false
                                      },
                                      change: _vm.textDefaultKeyChange,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "expandIcon",
                                          fn: function (props) {
                                            return [
                                              _c("a-icon", {
                                                attrs: {
                                                  type: "caret-right",
                                                  rotate: props.isActive
                                                    ? 90
                                                    : 0,
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.reDefaultKey,
                                      callback: function ($$v) {
                                        _vm.reDefaultKey = $$v
                                      },
                                      expression: "reDefaultKey",
                                    },
                                  },
                                  _vm._l(_vm.revisionList, function (page) {
                                    return _c(
                                      "a-collapse-panel",
                                      {
                                        key: page.pageId + "",
                                        style: _vm.customStyle,
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "header" },
                                            slot: "header",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  `第${page.sortOrderId}页`
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    `共${page.modifyHistoryList.length}个文本`
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          page.modifyHistoryList,
                                          function (item) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.textAreaId,
                                                ref:
                                                  item.textAreaId + "revision",
                                                refInFor: true,
                                                class:
                                                  _vm.selectRevisitionId ==
                                                  item.textAreaId
                                                    ? "local-content-list-show local-content-list-show-selected"
                                                    : "local-content-list-show",
                                                attrs: {
                                                  id:
                                                    item.textAreaId +
                                                    "revision",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toRevisiton(
                                                      page,
                                                      item,
                                                      page.sortOrderId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "local-content-list-num",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.textAreaSequenceNo
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "local-content-list",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "revisition-content",
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "0px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "justify-content":
                                                                "space-between",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              item.characters ||
                                                              item.textAreaTypeModifyHistory
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.revisionType ==
                                                                          1
                                                                          ? "翻译："
                                                                          : "校对："
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.textAreaTypeModifyHistoryPosition
                                                                    ? item.textAreaTypeModifyHistory
                                                                    : ""
                                                                )
                                                              ),
                                                            ]),
                                                            _vm.revisionType ==
                                                            1
                                                              ? _c("span", {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                  },
                                                                })
                                                              : _c("span", {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                  },
                                                                }),
                                                            item.changeSign &&
                                                            item.rejectComments &&
                                                            item.rejectComments
                                                              .length &&
                                                            item.canShow
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      item.changeSign >
                                                                      1
                                                                        ? "change-sign change-sign-over"
                                                                        : "change-sign",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeSign(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-tooltip",
                                                                      [
                                                                        _c(
                                                                          "template",
                                                                          {
                                                                            slot: "title",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.changeSign >
                                                                                      1
                                                                                      ? "标记为“未解决”"
                                                                                      : "标记为“已解决”"
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "a-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "check-circle",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        item.characters
                                                          ? _c("div", {
                                                              staticClass:
                                                                "space-text",
                                                              style:
                                                                "color: rgba(0, 0, 0, 0.55);",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    item.characters
                                                                      .map(
                                                                        (c) =>
                                                                          c.oldCharacter
                                                                      )
                                                                      .join("")
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    item.characters ||
                                                    item.textAreaTypeModifyHistory
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "revisition-content",
                                                            staticStyle: {
                                                              "padding-bottom":
                                                                "0px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.revisionType ==
                                                                        1
                                                                        ? "校对："
                                                                        : "终审："
                                                                    ) +
                                                                      _vm._s(
                                                                        !item.textAreaTypeModifyHistoryPosition
                                                                          ? item.textAreaTypeModifyHistory
                                                                          : ""
                                                                      )
                                                                  ),
                                                                ]),
                                                                _vm.revisionType ==
                                                                1
                                                                  ? _c("span", {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                        },
                                                                    })
                                                                  : _c("span", {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                        },
                                                                    }),
                                                              ]
                                                            ),
                                                            item.characters
                                                              ? _c("div", {
                                                                  staticClass:
                                                                    "space-text",
                                                                  style:
                                                                    _vm.openEditHistory
                                                                      ? "color: rgba(0, 0, 0, 0.55);padding-bottom: 5px;"
                                                                      : "padding-bottom: 5px;",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        item.characters
                                                                          .map(
                                                                            (
                                                                              c
                                                                            ) =>
                                                                              c.newCharacter
                                                                          )
                                                                          .join(
                                                                            ""
                                                                          )
                                                                      ),
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.checkPermission(
                                                      "errorsType:view"
                                                    ) &&
                                                    item.injuryType >= 1 &&
                                                    item.injuryType <= 3
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "revisition-content",
                                                            staticStyle: {
                                                              "padding-bottom":
                                                                "0px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "revisition-content-error",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "red",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "错误类型"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    class:
                                                                      _vm.selectRevisitionId ==
                                                                      item.textAreaId
                                                                        ? "action-span-select"
                                                                        : "action-span",
                                                                  },
                                                                  [
                                                                    (_vm
                                                                      .userInfo
                                                                      .id ==
                                                                      item.remarkUserId ||
                                                                      _vm.checkPermission(
                                                                        "errorsType:edit"
                                                                      ) ||
                                                                      _vm.checkPermission(
                                                                        "errorsType:dele"
                                                                      )) &&
                                                                    _vm.changeAll
                                                                      ? _c(
                                                                          "a-dropdown",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                trigger:
                                                                                  [
                                                                                    "click",
                                                                                  ],
                                                                                transitionName:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "more",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "a-menu",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    slot: "overlay",
                                                                                  },
                                                                                slot: "overlay",
                                                                              },
                                                                              [
                                                                                _vm.checkPermission(
                                                                                  "errorsType:edit"
                                                                                ) ||
                                                                                _vm
                                                                                  .userInfo
                                                                                  .id ==
                                                                                  item.remarkUserId
                                                                                  ? _c(
                                                                                      "a-menu-item",
                                                                                      {
                                                                                        key: "0",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.editLocalContent(
                                                                                                item
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                编辑\n                              "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm.checkPermission(
                                                                                  "errorsType:dele"
                                                                                ) ||
                                                                                _vm
                                                                                  .userInfo
                                                                                  .id ==
                                                                                  item.remarkUserId
                                                                                  ? _c(
                                                                                      "a-menu-item",
                                                                                      {
                                                                                        key: "1",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.deletRevisition(
                                                                                                item
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                删除\n                              "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            !item.editble
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "rgba(0,0,0,0.55)",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            item.injuryType ==
                                                                              3
                                                                              ? "错翻。"
                                                                              : item.injuryType ==
                                                                                2
                                                                              ? "硬伤。"
                                                                              : item.injuryType ==
                                                                                1
                                                                              ? "软伤。"
                                                                              : "无"
                                                                          ) +
                                                                          "\n                          " +
                                                                          _vm._s(
                                                                            item.remark
                                                                          ) +
                                                                          "\n                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "ocr-show-item-action-top",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ocr-show-item-action-radio",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              !item.injuryType
                                                                                ? "error-span"
                                                                                : "",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.changeInjuryType(
                                                                                    item,
                                                                                    0
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "无"
                                                                            ),
                                                                            !item.injuryType
                                                                              ? _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "check-circle",
                                                                                        theme:
                                                                                          "filled",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              item.injuryType ==
                                                                              3
                                                                                ? "error-span"
                                                                                : "",
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "red",
                                                                                "margin-left":
                                                                                  "3px",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.changeInjuryType(
                                                                                    item,
                                                                                    3
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "错"
                                                                            ),
                                                                            item.injuryType ==
                                                                            3
                                                                              ? _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "check-circle",
                                                                                        theme:
                                                                                          "filled",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              item.injuryType ==
                                                                              2
                                                                                ? "error-span"
                                                                                : "",
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "red",
                                                                                "margin-left":
                                                                                  "3px",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.changeInjuryType(
                                                                                    item,
                                                                                    2
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "硬"
                                                                            ),
                                                                            item.injuryType ==
                                                                            2
                                                                              ? _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "check-circle",
                                                                                        theme:
                                                                                          "filled",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              item.injuryType ==
                                                                              1
                                                                                ? "error-span"
                                                                                : "",
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "red",
                                                                                "margin-left":
                                                                                  "3px",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.changeInjuryType(
                                                                                    item,
                                                                                    1
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "软"
                                                                            ),
                                                                            item.injuryType ==
                                                                            1
                                                                              ? _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "check-circle",
                                                                                        theme:
                                                                                          "filled",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ocr-show-item-action-input",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        item.injuryType
                                                                          ? _c(
                                                                              "a-select",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    "show-search":
                                                                                      "",
                                                                                    placeholder:
                                                                                      "请选择错误类型",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.remark,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "remark",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.remark",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.dictList,
                                                                                function (
                                                                                  errorItem
                                                                                ) {
                                                                                  return _c(
                                                                                    "a-select-option",
                                                                                    {
                                                                                      key: errorItem.id,
                                                                                      attrs:
                                                                                        {
                                                                                          value:
                                                                                            errorItem.itemText,
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            errorItem.itemText
                                                                                          ) +
                                                                                          "\n                            "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "ocr-show-item-action-input-button",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a-button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    size: "small",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.cancelErrorEdit(
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              取消\n                            "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "a-button",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-left":
                                                                                      "10px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    type: "primary",
                                                                                    size: "small",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.changeError(
                                                                                        item,
                                                                                        page
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              保存\n                            "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.isReject &&
                                                    _vm.checkPermission(
                                                      "reject:view"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "revisition-content",
                                                            staticStyle: {
                                                              "padding-bottom":
                                                                "0px",
                                                            },
                                                          },
                                                          [
                                                            item.rejectComments &&
                                                            item.rejectComments
                                                              .length
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "rgba(0,0,0,0.65)",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        驳回理由：\n                      "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._l(
                                                              item.rejectComments,
                                                              function (
                                                                rejectItem
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: rejectItem.id,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            display:
                                                                              "flex",
                                                                            "align-items":
                                                                              "center",
                                                                            color:
                                                                              "rgba(0, 0, 0, 0.65)",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "margin-right":
                                                                                  "5px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: rejectItem.avatar,
                                                                                    alt: "",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                flex: "1",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "revisition-content-error",
                                                                                staticStyle:
                                                                                  {
                                                                                    height:
                                                                                      "20px",
                                                                                    "font-size":
                                                                                      "12px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        rejectItem.username
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      _vm.selectRevisitionId ==
                                                                                      item.textAreaId
                                                                                        ? "action-span-select"
                                                                                        : "action-span",
                                                                                  },
                                                                                  [
                                                                                    (rejectItem.userId ==
                                                                                      _vm
                                                                                        .userInfo
                                                                                        .id ||
                                                                                      _vm.checkPermission(
                                                                                        "reject:edit"
                                                                                      ) ||
                                                                                      _vm.checkPermission(
                                                                                        "reject:dele"
                                                                                      )) &&
                                                                                    _vm.changeAll
                                                                                      ? _c(
                                                                                          "a-dropdown",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                transitionName:
                                                                                                  "",
                                                                                                trigger:
                                                                                                  [
                                                                                                    "click",
                                                                                                  ],
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "a-icon",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    type: "more",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.stopPropagation()
                                                                                                    },
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "a-menu",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "overlay",
                                                                                                  },
                                                                                                slot: "overlay",
                                                                                              },
                                                                                              [
                                                                                                rejectItem.userId ==
                                                                                                  _vm
                                                                                                    .userInfo
                                                                                                    .id ||
                                                                                                _vm.checkPermission(
                                                                                                  "reject:edit"
                                                                                                )
                                                                                                  ? _c(
                                                                                                      "a-menu-item",
                                                                                                      {
                                                                                                        key: "0",
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.editLocalContentReject(
                                                                                                                rejectItem,
                                                                                                                item
                                                                                                              )
                                                                                                            },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                      编辑\n                                    "
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                                rejectItem.userId ==
                                                                                                  _vm
                                                                                                    .userInfo
                                                                                                    .id ||
                                                                                                _vm.checkPermission(
                                                                                                  "reject:dele"
                                                                                                )
                                                                                                  ? _c(
                                                                                                      "a-menu-item",
                                                                                                      {
                                                                                                        key: "1",
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.deletReject(
                                                                                                                rejectItem,
                                                                                                                item
                                                                                                              )
                                                                                                            },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                      删除\n                                    "
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    height:
                                                                                      "18px",
                                                                                    "font-size":
                                                                                      "12px",
                                                                                    "line-height":
                                                                                      "18px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    rejectItem.createTime
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "revision-content",
                                                                        staticStyle:
                                                                          {
                                                                            "padding-left":
                                                                              "25px",
                                                                            "padding-top":
                                                                              "5px",
                                                                            "padding-bottom":
                                                                              "5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "show-reject",
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "rgba(0, 0, 0, 0.65)",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                innerHTML:
                                                                                  _vm._s(
                                                                                    rejectItem.comment
                                                                                  ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    _vm.checkPermission(
                                                      "fk:view"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "revisition-content",
                                                            staticStyle: {
                                                              color:
                                                                "rgba(0, 0, 0, 0.65)",
                                                              "padding-bottom":
                                                                "0",
                                                            },
                                                          },
                                                          [
                                                            (_vm.selectRevisitionId ==
                                                              item.textAreaId ||
                                                              (item.feedbacks &&
                                                                item.feedbacks
                                                                  .length)) &&
                                                            _vm.changeAll
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "padding-bottom":
                                                                          "5px",
                                                                        "font-weight":
                                                                          "700",
                                                                        color:
                                                                          "block",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        反馈：\n                      "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            !_vm.isFeedback &&
                                                            _vm.selectRevisitionId ==
                                                              item.textAreaId &&
                                                            _vm.changeAll &&
                                                            _vm.checkPermission(
                                                              "fk:edit"
                                                            )
                                                              ? _c("j-editor", {
                                                                  ref:
                                                                    "localInput-revisition-" +
                                                                    item.textAreaId,
                                                                  refInFor: true,
                                                                  attrs: {
                                                                    placeholder:
                                                                      "ALT+回车换行，回车发送",
                                                                    maxWidth:
                                                                      "50px",
                                                                    toolbar: [],
                                                                  },
                                                                  on: {
                                                                    postValue:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.feedBackEnter(
                                                                          null,
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.feedBackValue,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "feedBackValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.feedBackValue",
                                                                  },
                                                                })
                                                              : _vm.isFeedback
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _vm._l(
                                                                      item.feedbacks,
                                                                      function (
                                                                        feedbackItem
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: feedbackItem.id,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    display:
                                                                                      "flex",
                                                                                    "align-items":
                                                                                      "center",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-right":
                                                                                          "5px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: feedbackItem.avatar,
                                                                                            alt: "",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        flex: "1",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "revisition-content-error",
                                                                                        staticStyle:
                                                                                          {
                                                                                            height:
                                                                                              "18px",
                                                                                            "font-size":
                                                                                              "12px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                feedbackItem.username
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            class:
                                                                                              _vm.selectRevisitionId ==
                                                                                              item.textAreaId
                                                                                                ? "action-span-select"
                                                                                                : "action-span",
                                                                                          },
                                                                                          [
                                                                                            (_vm.checkPermission(
                                                                                              "fk:edit"
                                                                                            ) ||
                                                                                              _vm.checkPermission(
                                                                                                "fk:dele"
                                                                                              ) ||
                                                                                              feedbackItem.userId ==
                                                                                                _vm
                                                                                                  .userInfo
                                                                                                  .id) &&
                                                                                            _vm.changeAll
                                                                                              ? _c(
                                                                                                  "a-dropdown",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        trigger:
                                                                                                          [
                                                                                                            "click",
                                                                                                          ],
                                                                                                        transitionName:
                                                                                                          "",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "a-icon",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            type: "more",
                                                                                                          },
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              $event.stopPropagation()
                                                                                                            },
                                                                                                        },
                                                                                                      }
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "a-menu",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            slot: "overlay",
                                                                                                          },
                                                                                                        slot: "overlay",
                                                                                                      },
                                                                                                      [
                                                                                                        feedbackItem.userId ==
                                                                                                          _vm
                                                                                                            .userInfo
                                                                                                            .id ||
                                                                                                        _vm.checkPermission(
                                                                                                          "fk:edit"
                                                                                                        )
                                                                                                          ? _c(
                                                                                                              "a-menu-item",
                                                                                                              {
                                                                                                                key: "0",
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.editLocalContent(
                                                                                                                        feedbackItem
                                                                                                                      )
                                                                                                                    },
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                        编辑\n                                      "
                                                                                                                ),
                                                                                                              ]
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        feedbackItem.userId ==
                                                                                                          _vm
                                                                                                            .userInfo
                                                                                                            .id ||
                                                                                                        _vm.checkPermission(
                                                                                                          "fk:dele"
                                                                                                        )
                                                                                                          ? _c(
                                                                                                              "a-menu-item",
                                                                                                              {
                                                                                                                key: "1",
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.deletFeedback(
                                                                                                                        feedbackItem
                                                                                                                      )
                                                                                                                    },
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                        删除\n                                      "
                                                                                                                ),
                                                                                                              ]
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            height:
                                                                                              "18px",
                                                                                            "font-size":
                                                                                              "12px",
                                                                                            "line-height":
                                                                                              "18px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              feedbackItem.createTime
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "revision-content",
                                                                                staticStyle:
                                                                                  {
                                                                                    "padding-left":
                                                                                      "25px",
                                                                                    "padding-top":
                                                                                      "5px",
                                                                                    "padding-bottom":
                                                                                      "5px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                !feedbackItem.editble
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        domProps:
                                                                                          {
                                                                                            innerHTML:
                                                                                              _vm._s(
                                                                                                feedbackItem.feedback
                                                                                              ),
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : feedbackItem.editble &&
                                                                                    _vm.selectRevisitionId ==
                                                                                      item.textAreaId
                                                                                  ? _c(
                                                                                      "j-editor",
                                                                                      {
                                                                                        ref:
                                                                                          "localInput-feedback-" +
                                                                                          item.textAreaId,
                                                                                        refInFor: true,
                                                                                        attrs:
                                                                                          {
                                                                                            placeholder:
                                                                                              "ALT+回车换行，回车发送",
                                                                                            maxWidth:
                                                                                              "50px",
                                                                                            toolbar:
                                                                                              [],
                                                                                          },
                                                                                        on: {
                                                                                          postValue:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.feedbackItemEditEnter(
                                                                                                null,
                                                                                                feedbackItem,
                                                                                                item
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              feedbackItem.feedback,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  feedbackItem,
                                                                                                  "feedback",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "feedbackItem.feedback",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    item.feedbacks.every(
                                                                      (
                                                                        feedbackItem
                                                                      ) =>
                                                                        !feedbackItem.editble
                                                                    )
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "padding-top":
                                                                                  "2px",
                                                                                "max-width":
                                                                                  "265px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm.selectRevisitionId ==
                                                                              item.textAreaId &&
                                                                            _vm.changeAll
                                                                              ? _c(
                                                                                  "j-editor",
                                                                                  {
                                                                                    ref:
                                                                                      "localInput-item-" +
                                                                                      item.textAreaId,
                                                                                    refInFor: true,
                                                                                    attrs:
                                                                                      {
                                                                                        placeholder:
                                                                                          "ALT+回车换行，回车发送",
                                                                                        maxWidth:
                                                                                          "50px",
                                                                                        toolbar:
                                                                                          [],
                                                                                      },
                                                                                    on: {
                                                                                      postValue:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.feedBackEnter(
                                                                                            null,
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          item.feedBackValue,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              item,
                                                                                              "feedBackValue",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "item.feedBackValue",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  item.id == 1
                    ? _c("div", { staticClass: "text-type" }, [
                        _vm.checkPermission("textType:view")
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.selectTextAreaData.length ||
                                        !_vm.checkPermission("textType:edit") ||
                                        (_vm.taskDetail.taskStatus !=
                                          "可开始" &&
                                          _vm.taskDetail.taskStatus !=
                                            "驳回") ||
                                        !_vm.changeAll,
                                      name: "radioGroup",
                                      "default-value": _vm.defualtTextTypeNo,
                                    },
                                    on: { change: _vm.textTypeChange },
                                    model: {
                                      value: _vm.defualtTextType,
                                      callback: function ($$v) {
                                        _vm.defualtTextType = $$v
                                      },
                                      expression: "defualtTextType",
                                    },
                                  },
                                  _vm._l(_vm.textTypes, function (item) {
                                    return _c(
                                      "a-radio",
                                      {
                                        key: item.sequenceNo,
                                        staticStyle: { "margin-top": "3px" },
                                        attrs: { value: item.sequenceNo },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.usage) +
                                            _vm._s(
                                              item.isBold ? "（加粗）" : ""
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "100px",
                                  isplay: "flex",
                                  "justify-content": "center",
                                  "align-items": "center",
                                },
                              },
                              [_c("span", [_vm._v("无权限")])]
                            ),
                      ])
                    : _vm._e(),
                  item.id == 7 &&
                  (_vm.showOptions.leftOptions.length == 1 ||
                    (_vm.showOptions.leftOptions.length > 1 &&
                      !_vm.showOptions.leftOptions[1].checked))
                    ? _c(
                        "div",
                        {
                          style: item.allHeight
                            ? { height: _vm.bottomHeight - 100 + "px" }
                            : "",
                        },
                        [
                          _c("search-modal", {
                            ref: "searchModal",
                            refInFor: true,
                            attrs: {
                              allHeight: item.allHeight,
                              bottomHeight: _vm.bottomHeight,
                              authList: _vm.authList,
                              showSeachIndex: _vm.showSeachIndex,
                              showImgLeftListPostions:
                                _vm.showImgLeftListPostions,
                              isRejectStatus: _vm.isRejectStatus,
                              taskDetail: _vm.taskDetail,
                              color: _vm.color,
                              changeAll: _vm.changeAll,
                              oldTextBatchStyle: _vm.oldTextBatchStyle,
                              pageListData: _vm.pageListData,
                            },
                            on: {
                              replaceSelectList: _vm.replaceSelectList,
                              savePageList: _vm.savePageList,
                              changeSelectList: _vm.changeSelectList,
                              getSearchList: _vm.getSearchList,
                              textDefaultKeyChange: _vm.textDefaultKeyChange,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.id == 6
                    ? _c(
                        "div",
                        {
                          staticClass: "log-list",
                          style: item.allHeight
                            ? {
                                maxHeight:
                                  _vm.bottomHeight - 100 + "px !important",
                              }
                            : "max-height: 500px;",
                        },
                        [
                          _vm.logList.length
                            ? _c(
                                "a-timeline",
                                _vm._l(_vm.logList, function (item) {
                                  return _c(
                                    "a-timeline-item",
                                    { key: item.id },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "log-list-item" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "log-list-item-left",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "log-list-item-left-left",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: item.avatar,
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "log-list-item-left-right",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(item.userName)
                                                    ),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(item.createTime)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "log-list-item-right",
                                            },
                                            [
                                              _vm._v(
                                                "【" +
                                                  _vm._s(item.actionDesc) +
                                                  "】" +
                                                  _vm._s(item.target)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-top": "15px",
                                    "padding-bottom": "15px",
                                  },
                                },
                                [_c("a-empty")],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.id == 101
                    ? _c(
                        "div",
                        { staticClass: "global-form", style: _vm.backCss },
                        [
                          _c(
                            "div",
                            { staticClass: "global-item" },
                            [
                              _c(
                                "a-row",
                                { staticStyle: { width: "100%" } },
                                [
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "global-item",
                                      attrs: { span: 6 },
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              "\n                    横向\n                  "
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "global-item-option",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.textLayoutChange(1)
                                                },
                                                mouseover: function ($event) {
                                                  _vm.color2 = _vm.color
                                                },
                                                mouseout: function ($event) {
                                                  _vm.color2 = "#4a4a4a"
                                                },
                                              },
                                            },
                                            [
                                              _c("text-rotation-none", {
                                                staticStyle: {
                                                  position: "relative",
                                                  top: "2px",
                                                },
                                                attrs: {
                                                  theme: "outline",
                                                  size: "18",
                                                  fill:
                                                    _vm.formData.textLayout == 1
                                                      ? _vm.color
                                                      : _vm.color2,
                                                  strokeWidth: 4,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              "\n                    竖向\n                  "
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "global-item-option",
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.textLayoutChange(2)
                                                },
                                                mouseover: function ($event) {
                                                  _vm.color1 = _vm.color
                                                },
                                                mouseout: function ($event) {
                                                  _vm.color1 = "#4a4a4a"
                                                },
                                              },
                                            },
                                            [
                                              _c("text-rotation-down", {
                                                attrs: {
                                                  theme: "outline",
                                                  size: "18",
                                                  fill:
                                                    _vm.formData.textLayout == 2
                                                      ? _vm.color
                                                      : _vm.color1,
                                                  strokeWidth: 4,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 18 } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            "line-height": "32px",
                                            "margin-right": "15px",
                                          },
                                        },
                                        [_vm._v("文本类型")]
                                      ),
                                      _c(
                                        "a-select",
                                        {
                                          staticStyle: {
                                            width: "191px",
                                            "font-size": "14px",
                                            "margin-top": "15px",
                                          },
                                          attrs: {
                                            "show-search": "",
                                            maxTagCount: 1,
                                            mode: "multiple",
                                            placeholder: "请选择文本类型",
                                          },
                                          model: {
                                            value:
                                              _vm.formData.textAreaTypeList,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "textAreaTypeList",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.textAreaTypeList",
                                          },
                                        },
                                        _vm._l(_vm.textTypes, function (item) {
                                          return _c(
                                            "a-select-option",
                                            { key: item.sequenceNo },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item.usage) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "global-item" },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                字体\n              "
                                    ),
                                  ]),
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: {
                                        width: "160px",
                                        "font-size": "14px",
                                      },
                                      attrs: {
                                        dropdownClassName: "global-select",
                                        placeholder: "请选择字体",
                                        "defualt-value":
                                          _vm.formData.fontFamilyId || "",
                                      },
                                      on: { change: _vm.fontFamilyChange },
                                      model: {
                                        value: _vm.formData.fontFamilyId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "fontFamilyId",
                                            $$v
                                          )
                                        },
                                        expression: "formData.fontFamilyId",
                                      },
                                    },
                                    _vm._l(_vm.fontFamilyList, function (item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.id,
                                          attrs: { value: item.id },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.displayName) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                字体样式\n              "
                                    ),
                                  ]),
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        "font-size": "14px",
                                        "margin-left": "10px",
                                      },
                                      attrs: {
                                        placeholder: "字体样式",
                                        "defualt-value":
                                          _vm.formData.styleName || "",
                                        disabled: true,
                                      },
                                      model: {
                                        value: _vm.formData.styleName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "styleName",
                                            $$v
                                          )
                                        },
                                        expression: "formData.styleName",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select-option",
                                        {
                                          key: _vm.formData.styleName,
                                          attrs: {
                                            value: _vm.formData.styleName,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.formData.styleName) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                字号\n              "
                                    ),
                                  ]),
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: {
                                        width: "80px",
                                        "font-size": "14px",
                                        "margin-left": "10px",
                                      },
                                      attrs: {
                                        "filter-option": _vm.filterOption,
                                        "show-search": "",
                                        placeholder: "请选择字号",
                                      },
                                      on: { search: _vm.fontChange },
                                      model: {
                                        value: _vm.formData.fontSize,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "fontSize",
                                            $$v
                                          )
                                        },
                                        expression: "formData.fontSize",
                                      },
                                    },
                                    _vm._l(_vm.fontSizes, function (item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.label,
                                          attrs: { value: item.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.label) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "global-item" },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                加粗\n              "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "global-item-option",
                                      on: { click: _vm.boldChange },
                                    },
                                    [
                                      _c("a-icon", {
                                        style: _vm.formData.isBold
                                          ? { color: _vm.color }
                                          : "",
                                        attrs: { type: "bold" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                斜体\n              "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "global-item-option",
                                      staticStyle: { "margin-left": "10px" },
                                      on: { click: _vm.isInclineChange },
                                    },
                                    [
                                      _c("a-icon", {
                                        style: _vm.formData.isIncline
                                          ? { color: _vm.color }
                                          : "",
                                        attrs: { type: "italic" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                字体颜色\n              "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "global-item-option",
                                      staticStyle: { "margin-left": "10px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.colorClick("FontColor")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "color-change" },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "color-change-text",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      A\n                    "
                                                ),
                                              ]
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "color-change-bottom",
                                              style: {
                                                background:
                                                  _vm.formData.fontColor,
                                                border:
                                                  "1px solid rgba(0,0,0,0.25)",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _c("colorPicker", {
                                        ref: "colorPickerFontColor",
                                        refInFor: true,
                                        attrs: {
                                          defaultColor:
                                            _vm.formData.fontColor || "",
                                        },
                                        model: {
                                          value: _vm.formData.fontColor,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "fontColor",
                                              $$v
                                            )
                                          },
                                          expression: "formData.fontColor",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                字体描边\n              "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "global-item-option",
                                      staticStyle: { "margin-left": "10px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.colorClick("FontStroke")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "color-change" },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "color-change-stroke-text",
                                                style: `height:19px;line-height:18px;-webkit-text-stroke: 1px rgba(0,0,0,0.65)`,
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      A\n                    "
                                                ),
                                              ]
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "color-change-bottom-stroke",
                                              style: {
                                                background:
                                                  _vm.formData.strokeColor,
                                                border:
                                                  "1px solid rgba(0,0,0,0.25)",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _c("colorPicker", {
                                        ref: "colorPickerFontStroke",
                                        refInFor: true,
                                        attrs: {
                                          defaultColor:
                                            _vm.formData.strokeColor || "",
                                        },
                                        model: {
                                          value: _vm.formData.strokeColor,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "strokeColor",
                                              $$v
                                            )
                                          },
                                          expression: "formData.strokeColor",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                描边宽度\n              "
                                    ),
                                  ]),
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: {
                                        width: "70px",
                                        "font-size": "14px",
                                        "margin-left": "10px",
                                      },
                                      attrs: {
                                        "show-search": "",
                                        placeholder: "请选择描边宽度",
                                      },
                                      model: {
                                        value: _vm.formData.strokeWidth,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "strokeWidth",
                                            $$v
                                          )
                                        },
                                        expression: "formData.strokeWidth",
                                      },
                                    },
                                    _vm._l(_vm.strokeWidths, function (i) {
                                      return _c("a-select-option", { key: i }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(i) +
                                            "\n                "
                                        ),
                                      ])
                                    }),
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "a-tooltip",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    top: "1px",
                                  },
                                },
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n                对齐方式\n              "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "global-item-option",
                                          style: { marginLeft: "10px" },
                                        },
                                        [
                                          _c("align-text-left", {
                                            attrs: {
                                              theme: "outline",
                                              size: "19",
                                              fill:
                                                _vm.formData.textAlign == "left"
                                                  ? _vm.color
                                                  : _vm.color11,
                                              strokeWidth: 4,
                                            },
                                            on: {
                                              mouseover: function ($event) {
                                                _vm.color11 = _vm.color
                                              },
                                              mouseout: function ($event) {
                                                _vm.color11 = "#4a4a4a"
                                              },
                                              click: function ($event) {
                                                return _vm.textAlignChange(
                                                  "left"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "global-item-option",
                                          style: { marginLeft: "10px" },
                                        },
                                        [
                                          _c("align-text-center", {
                                            attrs: {
                                              theme: "outline",
                                              size: "19",
                                              fill:
                                                _vm.formData.textAlign ==
                                                "center"
                                                  ? _vm.color
                                                  : _vm.color12,
                                              strokeWidth: 4,
                                            },
                                            on: {
                                              mouseover: function ($event) {
                                                _vm.color12 = _vm.color
                                              },
                                              mouseout: function ($event) {
                                                _vm.color12 = "#4a4a4a"
                                              },
                                              click: function ($event) {
                                                return _vm.textAlignChange(
                                                  "center"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "global-item-option",
                                          style: { marginLeft: "10px" },
                                        },
                                        [
                                          _c("align-text-right", {
                                            attrs: {
                                              theme: "outline",
                                              size: "19",
                                              fill:
                                                _vm.formData.textAlign ==
                                                "right"
                                                  ? _vm.color
                                                  : _vm.color13,
                                              strokeWidth: 4,
                                            },
                                            on: {
                                              mouseover: function ($event) {
                                                _vm.color13 = _vm.color
                                              },
                                              mouseout: function ($event) {
                                                _vm.color13 = "#4a4a4a"
                                              },
                                              click: function ($event) {
                                                return _vm.textAlignChange(
                                                  "right"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "global-item",
                              staticStyle: {
                                "padding-top": "0px",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "a-row",
                                { staticStyle: { width: "100%" } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            "line-height": "32px",
                                            "margin-right": "15px",
                                          },
                                        },
                                        [_vm._v("字高")]
                                      ),
                                      _c(
                                        "a-select",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            "font-size": "14px",
                                            "margin-top": "15px",
                                          },
                                          attrs: {
                                            "show-search": "",
                                            placeholder: "请选择字高",
                                          },
                                          model: {
                                            value: _vm.formData.textHeight,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "textHeight",
                                                $$v
                                              )
                                            },
                                            expression: "formData.textHeight",
                                          },
                                        },
                                        _vm._l(_vm.scaleY, function (i) {
                                          return _c(
                                            "a-select-option",
                                            { key: i.value },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(i.label) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            "line-height": "32px",
                                            "margin-right": "15px",
                                          },
                                        },
                                        [_vm._v("字宽")]
                                      ),
                                      _c(
                                        "a-select",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            "font-size": "14px",
                                            "margin-top": "15px",
                                          },
                                          attrs: {
                                            "show-search": "",
                                            placeholder: "请选择字宽",
                                          },
                                          model: {
                                            value: _vm.formData.textWidth,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "textWidth",
                                                $$v
                                              )
                                            },
                                            expression: "formData.textWidth",
                                          },
                                        },
                                        _vm._l(_vm.scaleX, function (i) {
                                          return _c(
                                            "a-select-option",
                                            { key: i.value },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(i.label) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "global-item",
                              staticStyle: {
                                "padding-bottom": "20px",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "a-row",
                                { staticStyle: { width: "100%" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "line-height": "32px",
                                        "margin-right": "15px",
                                      },
                                    },
                                    [_vm._v("行距")]
                                  ),
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        "font-size": "14px",
                                      },
                                      attrs: {
                                        "show-search": "",
                                        placeholder: "请选择行距",
                                      },
                                      model: {
                                        value: _vm.formData.lineSpacing,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "lineSpacing",
                                            $$v
                                          )
                                        },
                                        expression: "formData.lineSpacing",
                                      },
                                    },
                                    _vm._l(_vm.lineHeight, function (i) {
                                      return _c(
                                        "a-select-option",
                                        { key: i.value },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(i.label) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  item.id == 101
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "padding-right": "25px",
                            "padding-top": "15px",
                            "padding-bottom": "15px",
                            width: "100%",
                            "justify-content": "end",
                            "border-top": "1px solid rgba(0, 0, 0, 0.1)",
                          },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: {
                                "margin-right": "15px",
                                "font-size": "12px",
                                "padding-left": "10px",
                                "padding-right": "10px",
                              },
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeStyle(item)
                                },
                              },
                            },
                            [_vm._v("\n            取消\n          ")]
                          ),
                          _c(
                            "a-button",
                            {
                              style: _vm.gloabalBtnLoading
                                ? "font-size:12px;padding-left:20px;padding-right:10px"
                                : "font-size:12px;padding-left:10px;padding-right:10px",
                              attrs: {
                                size: "small",
                                loading: _vm.gloabalBtnLoading,
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveStyle(item)
                                },
                              },
                            },
                            [_vm._v("\n            确定\n          ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      }),
      _c("errorModal", {
        ref: "errorModal",
        attrs: { dictList: [], selectErrorType: 4 },
        on: {
          changeIsDele: _vm.changeIsDele,
          postRejectValue: _vm.postRejectValue,
        },
      }),
      _c("playvideo-modal", {
        ref: "videoModal",
        attrs: { width: _vm.imagePlayWidth },
        on: { changeVideoWidth: _vm.changeVideoWidth },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }